import { useEffect, useState } from "react";
import { CalendarEvent } from "../../models/CalendarEvent"
import { useCalendar } from "../../Helper/CalendarContext";

interface Export {
    holidayDates: string[] | undefined;
    days: Date[];
    date: Date;
    handleDateChange: (date: Date) => void;
    loading: boolean;
}

export const useCalendarV2 = (): Export => {
    const { days, date, handleDateChange, loading, groupedByType } = useCalendar();
    const [holidayDates, setHolidayDates] = useState<string[] | undefined>(undefined);

    useEffect(() => {
        if (groupedByType) {
            const filteredHolidays = Object.entries(groupedByType).reduce((acc, [key, events]) => {
                // Filter events where Type === 5
                const filteredEvents = events.filter(event => event.Type === 5);
                if (filteredEvents.length > 0) {
                    acc[Number(key)] = filteredEvents;
                }
                return acc;
            }, {} as Record<number, CalendarEvent[]>);

            const holidayDates = filteredHolidays
                ? Object.values(filteredHolidays).flat().map((holiday) => holiday.Date) // Assuming holidays have a `date` property
                : [];

            setHolidayDates(holidayDates);
        }
        else {
            setHolidayDates(undefined);
        }
    }, [groupedByType]);

    return {
        holidayDates,
        days,
        date,
        handleDateChange,
        loading
    }
}