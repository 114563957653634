import './DayInfo.css';
import { useCalendar } from "../../Helper/CalendarContext";

const DAY_NAMES = [
    "Sekmadienis",
    "Pirmadienis",
    "Antradienis",
    "Trečiadienis",
    "Ketvirtadienis",
    "Penktadienis",
    "Šeštadienis"
]

const TYPE_NAMES = [
    "",
    "",
    "",
    "",
    "",
    "Šventinės dienos",
    "Šventinės dienos",
    "Sezonines dienos",
    "Šventinės dienos",
    "Vardadieniai"
]

export const DayInfo = () => {
    const { loading, date, groupedByTypeToday } = useCalendar();

    if (groupedByTypeToday === undefined || loading) {
        return <div className="day-info-wrapper loading">
            <div>Kraunama informacija...</div>
        </div>;
    }

    return <div className="day-info-wrapper">
        <h1>{DAY_NAMES[date.getDay()]}</h1>
        <h2>{date.getDate()} diena</h2>
        {Object.keys(groupedByTypeToday).length === 0 ?
            <div className='day-info-wrapper'>Nėra jokių įvykių.</div>
            :
            Object.entries(groupedByTypeToday).map(([type, events]) => (
                <div key={type} className="event-group">
                    <h1>{TYPE_NAMES[Number(type)]}</h1>
                    {events.map((event) => (
                        <ul key={event.Id} className="event-item">
                            <li>{Number(type) === 9 ? event.Name.split(';')[1] : event.TranslatedName}</li>
                        </ul>
                    ))}
                </div>
            ))}
    </div>
}