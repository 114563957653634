import { useMoonPhase } from "./useMoonPhase";
import './MoonPhase.css';

export const MoonPhase = () => {
    const { moonPhase } = useMoonPhase();

    return (
        <div className="moon-phase-container">
            <div className="moon-info">
                <h1>Mėnulio fazės</h1>
                <div className="moon-phases">
                    {moonPhase !== undefined ?
                        moonPhase?.NearbyStages.map((x, index) =>
                            <div className={"moon-phase-name " + (index == 1 ? "current" : "")}>
                                {index == 1 && <div className="moon-phase-name-current">Dabartinė</div>}
                                <div>{x}</div>
                            </div>)
                        :
                        <div className="moon-phase-loading">Kraunama...</div>}
                </div>
            </div>
            <div className="moon-container-wrapper">
                <div className="moon-container">
                    <div className="moon">
                        {moonPhase !== undefined ?
                            <div className="moon-image" style={{ backgroundImage: `url('/images/${moonPhase?.ImageName}')` }}></div>
                            :
                            <div className="moon-image moon-phase-loading">Kraunama...</div>}
                    </div>
                    {moonPhase !== undefined ?
                        <div className="moon-illumination">{moonPhase?.Illumination}</div>
                        :
                        <div className="moon-phase-loading">Kraunama...</div>
                    }
                </div>
            </div>
        </div>
    );
};