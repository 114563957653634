import React from "react";
import { Helmet } from "react-helmet-async";

interface SEOProps {
  title: string;
  description: string;
  url: string; // JSON-LD
}

export const SEO: React.FC<SEOProps> = ({ title, description, url }) => (
  <Helmet>
    <title>{title}</title>
    <meta
      name="description"
      content={description}
    />
    <link
      rel="canonical"
      href={url}
    />
  </Helmet>
);

