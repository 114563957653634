import React, { createContext, useContext, useEffect, useState } from "react";
import {
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    addDays
} from "date-fns";
import axios, { AxiosError } from "axios";
import { CalendarEvent } from "../models/CalendarEvent";

interface CalendarContextProps {
    days: Date[];
    events: CalendarEvent[];
    loading: boolean;
    error: string | null;
    date: Date;
    handleDateChange: (date: Date) => void;
    groupedByTypeToday: Record<number, CalendarEvent[]> | undefined;
    groupedByType: Record<number, CalendarEvent[]> | undefined;
}

const CalendarContext = createContext<CalendarContextProps | undefined>(undefined);

export const CalendarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [date, setDate] = useState<Date>(new Date());
    const [days, setDays] = useState<Date[]>([]);
    const [groupedByTypeToday, setGroupedByTypeToday] = useState<Record<number, CalendarEvent[]> | undefined>(undefined);
    const [groupedByType, setGroupedByType] = useState<Record<number, CalendarEvent[]> | undefined>(undefined);
    const [month, setMonth] = useState<number | undefined>(undefined);

    // Generate days for the calendar
    useEffect(() => {
        const startDate = startOfWeek(startOfMonth(date), { weekStartsOn: 0 });
        const endDate = endOfWeek(endOfMonth(date), { weekStartsOn: 0 });
        const tempDays: Date[] = [];
        let currentDay = startDate;
        while (currentDay <= endDate) {
            tempDays.push(currentDay);
            currentDay = addDays(currentDay, 1);
        }
        setDays(tempDays);

        setMonth(date.getMonth());
    }, [date]);

    useEffect(() => {
        if (month !== undefined){
            const year = date.getFullYear();
            const tempMonth = date.getMonth() + 1;
    
            const fetchEvents = async () => {
                setLoading(true);
                setError(null);
    
                try {
                    const response = await axios.get(
                        `https://virtualelibrary.com/wp-json/calendar/v1/events/${year}/${tempMonth}`
                    );
                    setEvents(response.data);
                } catch (err) {
                    if (err instanceof AxiosError) {
                        setError("Failed to fetch events. Please try again later.");
                    } else {
                        setError("An unknown error occurred.");
                    }
                    setEvents([]);
                } finally {
                    setLoading(false);
                }
            };
    
            if (year && tempMonth) fetchEvents();
            else {
                setEvents([]);
            }
        }
    }, [month]);

    // Group events by type
    useEffect(() => {
        const tempGroupedByType = events.reduce((acc, event) => {
            if (!acc[event.Type]) acc[event.Type] = [];
            acc[event.Type].push(event);
            return acc;
        }, {} as Record<number, CalendarEvent[]>);

        setGroupedByType(tempGroupedByType);
    }, [events]);

    useEffect(() => {
        const tempGroupedByTypeToday = events
            .filter(event => {
                const eventDate = new Date(event.Date);
                return (
                    eventDate.getFullYear() === date.getFullYear() &&
                    eventDate.getMonth() === date.getMonth() &&
                    eventDate.getDate() === date.getDate()
                );
            })
            .reduce((acc, event) => {
                if (!acc[event.Type]) acc[event.Type] = [];
                acc[event.Type].push(event);
                return acc;
            }, {} as Record<number, CalendarEvent[]>);
        setGroupedByTypeToday(tempGroupedByTypeToday);
    }, [date, events]);

    const handleDateChange = (newDate: Date) => setDate(newDate);

    return (
        <CalendarContext.Provider
            value={{
                days,
                events,
                loading,
                error,
                date,
                handleDateChange,
                groupedByTypeToday,
                groupedByType
            }}
        >
            {children}
        </CalendarContext.Provider>
    );
};

export const useCalendar = (): CalendarContextProps => {
    const context = useContext(CalendarContext);
    if (!context) {
        throw new Error("useCalendar must be used within a CalendarProvider");
    }
    return context;
};
