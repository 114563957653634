import {
    format,
    isSameMonth,
    isSameDay,
    subMonths,
    addMonths,
    startOfMonth
} from "date-fns";
import "./CalendarV2.css";
import { getMonthNameInLithuanian } from "../Pages/CalendarPage";
import { useCalendarV2 } from "./useCalendarV2";

export const CalendarV2 = () => {
    const {days, date, handleDateChange, loading, holidayDates} = useCalendarV2();

    const isWeekend = (date: Date) => {
        const day = date.getDay();
        return day === 0 || day === 6; // 0 for Sunday, 6 for Saturday
    };
    // Get previous month and next month dates
    const previousMonth = startOfMonth(subMonths(date, 1)); // Subtract one month
    const nextMonth = startOfMonth(addMonths(date, 1)); // Add one month
    // Format their names in Lithuanian
    const previousMonthName = getMonthNameInLithuanian(previousMonth);
    const nextMonthName = getMonthNameInLithuanian(nextMonth);

    if (loading || holidayDates === undefined) {
        return <div className="calendar-select-wrapper">
            <div className="calendar-select">
                <div className="calendar-header">
                    {["S", "P", "A", "T", "K", "P", "Š"].map((day) => (
                        <div key={day} className={"day-header " + (day === "S" || day === "Š" ? "weekend" : "")}>
                            {day}
                        </div>
                    ))}
                </div>
                <div className="calendar-loading">
                    Kraunama informacija...
                </div>
            </div>
        </div>;
    }

    const isHoliday = (day: Date) => {
        return holidayDates.some((holidayDate) => isSameDay(holidayDate, day))
    };

    return (
        <div className="calendar-select-wrapper">
            <div className="calendar-select">
                <div className="calendar-header">
                    {["S", "P", "A", "T", "K", "P", "Š"].map((day) => (
                        <div key={day} className={"day-header " + (day === "S" || day === "Š" ? "weekend" : "")}>
                            {day}
                        </div>
                    ))}
                </div>
                <div className="calendar-grid">
                    {days.map((day, index) => (
                        <div
                            key={index}
                            className={`calendar-day${!isSameMonth(day, date) ? " inactive" : ""
                                }${isSameDay(day, date) ? " selected" : ""}${isWeekend(day) ? " weekend" : ""}${isHoliday(day) ? " holiday" : ""}`}
                            onClick={() => handleDateChange(day)}
                        >
                            {format(day, "d")}
                        </div>
                    ))}
                </div>
                <div className={"month-select-container"}>
                    <button onClick={() => handleDateChange(previousMonth)}>
                        {previousMonthName}
                    </button>
                    <button onClick={() => handleDateChange(nextMonth)}>
                        {nextMonthName}
                    </button>
                </div>
            </div>
        </div>
    );
};