import { useEffect, useState } from "react";
import { Moon } from "../../models/Moon";
import { useCalendar } from "../../Helper/CalendarContext";

const LUNAR_MONTH = 29.530588853;
const PHASE_ARRAY = [
    "Jaunatis",
    "Jaunatis (pilnėja)",
    "Priešpilnis",
    "Priešpilnis (pilnėja)",
    "Pilnatis",
    "Dylanti pilnatis",
    "Delčia",
    "Dylanti delčia"
]
const PHASES = [
    { day: 0.0, name: "New Moon", image: "moon-0.0.jpg", translation: PHASE_ARRAY[0] },
    { day: 1.5, name: "New Moon", image: "moon-2.0.jpg", translation: PHASE_ARRAY[1] },
    { day: 2.0, name: "New Moon", image: "moon-2.0.jpg", translation: PHASE_ARRAY[1] },
    { day: 3.0, name: "Waxing Crescent", image: "moon-3.0.jpg", translation: PHASE_ARRAY[1]  },
    { day: 4.0, name: "First Quarter", image: "moon-4.0.jpg", translation: PHASE_ARRAY[1]  },
    { day: 5.0, name: "Waxing Crescent", image: "moon-5.0.jpg", translation: PHASE_ARRAY[1]  },
    { day: 6.0, name: "First Quarter", image: "moon-6.0.jpg", translation: PHASE_ARRAY[1]  },
    { day: 7.4, name: "Waxing Gibbous", image: "moon-7.3.jpg", translation: PHASE_ARRAY[2]  },
    { day: 8.4, name: "Full Moon", image: "moon-8.4.jpg", translation: PHASE_ARRAY[3]  },
    { day: 9.1, name: "Waning Gibbous", image: "moon-9.1.jpg", translation: PHASE_ARRAY[3] },
    { day: 10.1, name: "Last Quarter", image: "moon-10.1.jpg", translation: PHASE_ARRAY[3] },
    { day: 11.1, name: "Waning Crescent", image: "moon-11.1.jpg", translation: PHASE_ARRAY[3] },
    { day: 12.1, name: "New Moon", image: "moon-12.1.jpg", translation: PHASE_ARRAY[3] },
    { day: 13.4, name: "Waxing Crescent", image: "moon-13.2.jpg", translation: PHASE_ARRAY[3] },
    { day: 14.4, name: "First Quarter", image: "moon-14.1.jpg", translation: PHASE_ARRAY[4] },
    { day: 15.4, name: "Waxing Gibbous", image: "moon-15.1.jpg", translation: PHASE_ARRAY[5] },
    { day: 16.2, name: "Full Moon", image: "moon-16.2.jpg", translation: PHASE_ARRAY[5] },
    { day: 17.6, name: "Waning Gibbous", image: "moon-17.6.jpg", translation: PHASE_ARRAY[5] },
    { day: 18.6, name: "Last Quarter", image: "moon-18.6.jpg", translation: PHASE_ARRAY[5] },
    { day: 19.6, name: "Waning Crescent", image: "moon-19.6.jpg", translation: PHASE_ARRAY[5] },
    { day: 20.6, name: "New Moon", image: "moon-20.6.jpg", translation: PHASE_ARRAY[5] },
    { day: 21.2, name: "Waxing Crescent", image: "moon-21.2.jpg", translation: PHASE_ARRAY[5] },
    { day: 22.2, name: "First Quarter", image: "moon-22.2.jpg", translation: PHASE_ARRAY[6] },
    { day: 23.2, name: "Waxing Gibbous", image: "moon-23.2.jpg", translation: PHASE_ARRAY[7] },
    { day: 24.2, name: "Full Moon", image: "moon-24.2.jpg", translation: PHASE_ARRAY[7] },
    { day: 25.2, name: "Waning Gibbous", image: "moon-25.2.jpg", translation: PHASE_ARRAY[7] },
    { day: 26.2, name: "Last Quarter", image: "moon-26.2.jpg", translation: PHASE_ARRAY[7] },
    { day: 27.7, name: "Waning Crescent", image: "moon-0.0.jpg", translation: PHASE_ARRAY[7] },
    { day: 29.0, name: "Waning Crescent", image: "moon-0.0.jpg", translation: PHASE_ARRAY[0] }
];

interface Export {
    moonPhase: Moon | undefined;
}

export const useMoonPhase = () : Export => {
    const { date } = useCalendar();
    const [moonPhase, setMoonPhase] = useState<Moon | undefined>(undefined);

    const getBeforeStageIndex = (currentPhase : string) =>{
        var currentIndex = PHASE_ARRAY.findIndex(x=> x === currentPhase);
        let newIndex = currentIndex - 1;
        if (newIndex < 0){
            newIndex = PHASE_ARRAY.length -1;
        }
        return newIndex;
    }

    const getAfterStageIndex = (currentPhase : string) =>{
        var currentIndex = PHASE_ARRAY.findIndex(x=> x === currentPhase);
        let newIndex = currentIndex + 1;
        if (newIndex > PHASE_ARRAY.length -1){
            newIndex = 0;
        }
        return newIndex;
    }

    const getJulianDate = (date = new Date()) => {
        const time = date.getTime();
        const tzoffset = date.getTimezoneOffset()
        return (time / 86400000) - (tzoffset / 1440) + 2440587.5;
    }

    const getLunarAge = (date = new Date()) => {
        const percent = normalize((getJulianDate(date) - 2451550.1) / LUNAR_MONTH);
        const age = percent * LUNAR_MONTH;
        return age;
    }
    const normalize = (value: number) => {
        value = value - Math.floor(value);
        if (value < 0)
            value = value + 1
        return value;
    }

    const getLunarPhase = (date: Date = new Date()): Moon => {
        const age = getLunarAge(date);

        // Find the closest phase to the current moon age
        let closestPhase = PHASES[0];
        for (let i = 1; i < PHASES.length; i++) {
            if (Math.abs(PHASES[i].day - age) < Math.abs(closestPhase.day - age)) {
                closestPhase = PHASES[i];
            }
        }

        // Return the phase object
        return {
            Name: `${closestPhase.translation} - ${closestPhase.day.toFixed(1)} Days`,
            ImageName: closestPhase.image,
            Illumination: getMoonIllumination(date),
            NearbyStages: [PHASE_ARRAY[getBeforeStageIndex(closestPhase.translation)], closestPhase.translation, PHASE_ARRAY[getAfterStageIndex(closestPhase.translation)]]
        };
    };

    const getMoonIllumination = (date: Date = new Date()): string => {
        const lunarAge = getLunarAge(date); // Get the lunar age in days
        const radians = (2 * Math.PI * lunarAge) / LUNAR_MONTH; // Convert to radians
        const illumination = 50 * (1 - Math.cos(radians)); // Calculate illumination percentage
        return `${illumination.toFixed(1)}%`; // Return as a formatted percentage
    };

    useEffect(() => {
        if (date !== undefined){
            setMoonPhase(getLunarPhase(date)); // Set moon phase on date change
        }
    }, [date]);

    return {
        moonPhase
    }
}