import {  useEffect } from "react";
import { useParams } from "react-router-dom";
import { CalendarV2 } from "../Calendar/CalendarV2";
import { MoonPhase } from "../Moon/MoonPhase";
import '../Global/Global.css';
import './CalendarPage.css';
import { DayInfo } from "../DayInfo/DayInfo";
import { SEO } from "../Seo/Helmet";
import { useCalendar } from "../../Helper/CalendarContext";

export const getMonthNameInLithuanian = (date: Date): string => {
    return new Intl.DateTimeFormat("lt-LT", { month: "long" }).format(date);
};

export const CalendarPage = () => {
    const { error, date: displayDate, handleDateChange } = useCalendar();
    const { date : urlDate } = useParams<{ date?: string }>();

    useEffect(() => {
        const tempDate = urlDate ? new Date(urlDate) : new Date();
        handleDateChange(tempDate);
    }, [urlDate])

    const seo = () => <SEO
        title={"Kalendorius"}
        description={"Tinklapis, kuriame galite matyti kalendorių ir vardadienių, švenčių datas."}
        url={`/${displayDate.getFullYear()}-${(displayDate.getMonth() + 1).toString().padStart(2, '0')}-${displayDate.getDate().toString().padStart(2, '0')}`} />;

    const currentMonthName = getMonthNameInLithuanian(displayDate);

    return (
        <>
            {seo()}
            <div>{error}</div>
            <div className="calendar-wrapper">
                <div className="calendar-wrapper-effect"></div>
                <div className="content">
                    <h1 className="main-title">{displayDate.getFullYear()} Metų Kalendorius</h1>
                    <div className="calendar">
                        <div className="calendar-top">
                            <MoonPhase />
                            <h1 className="calendar-top-title">{displayDate.getFullYear()} {currentMonthName}</h1>
                        </div>
                        <div className="calendar-middle">
                            <CalendarV2 />
                            <DayInfo />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CalendarPage;
