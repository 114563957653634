import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CalendarPage } from './components/Pages/CalendarPage';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Default route */}
        <Route path="/" element={<CalendarPage />} />

        {/* Dynamic route for user-input dates */}
        <Route path="/:date" element={<CalendarPage />} />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<div>Page Not Found</div>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
